import report1 from "./../images/IMG_4627.jpg"
import report2 from "./../images/IMG_4626.jpg"
import report3 from "./../images/DC2E8B54-34E7-4850-AA61-8B60C63D53EE.jpg"
import report4 from "./../images/59F02507-A0CF-4D09-B51F-D54D16E16070.png"
import report5 from "./../images/IMG_0490.png"
import report6 from "./../images/Hacker_Report_5.png"
import report7 from "./../images/Hacker_Report_7.png"
import report8 from "./../images/Hacker_Report_8.png"
import report9 from "./../images/Hacker_Report_9.png"
import {css} from "@emotion/react";

const HackerReports = () => {

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    import('./../Hacker-Reports.css')
    return <div>
        <section className="u-clearfix u-image u-section-1" id="sec-b052" data-image-width="640"
                 data-image-height="360">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-text u-text-body-alt-color u-text-default u-text-1">Hacker
                    Reports</h2>
                <div
                    className="u-carousel u-gallery u-gallery-slider u-layout-carousel u-lightbox u-show-text-on-hover u-gallery-1"
                    id="carousel-f035" data-interval="5000" data-u-ride="carousel" data-pswp-uid="1">
                    <ol className="u-absolute-hcenter u-carousel-indicators u-carousel-indicators-1">
                        <li data-u-target="#carousel-f035" data-u-slide-to="0"
                            className="u-grey-70 u-shape-circle u-active" style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="1" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="2" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="3" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="4" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="5" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="6" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="7" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                        <li data-u-target="#carousel-f035" data-u-slide-to="8" className="u-grey-70 u-shape-circle"
                            style={{width: '10px', height: '10px'}} ></li>
                    </ol>
                    <div className="u-carousel-inner u-gallery-inner" role="listbox">
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-1 u-carousel-item-next u-carousel-item-left" data-pswp-item-id="0" data-gallery-uid="1">
                            <div className="u-back-slide" data-image-width="630" data-image-height="787">
                                <img className="u-back-image u-expanded" src={report1} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-1">
                                <h3 className="u-gallery-heading">Sample Title</h3>
                                <p className="u-gallery-text">Sample Text</p>
                            </div>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-2" data-pswp-item-id="1" data-gallery-uid="1">
                            <div className="u-back-slide" data-image-width="630" data-image-height="787">
                                <img className="u-back-image u-expanded" src={report2} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-2">
                                <h3 className="u-gallery-heading">Sample Title</h3>
                                <p className="u-gallery-text">Sample Text</p>
                            </div>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-3" data-image-width="1638" data-image-height="2048" data-pswp-item-id="2" data-gallery-uid="1">
                            <div className="u-back-slide">
                                <img className="u-back-image u-expanded" src={report3} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-3">
                                <h3 className="u-gallery-heading"></h3>
                                <p className="u-gallery-text"></p>
                            </div>
                            <style data-mode="XL" data-visited="true"></style>
                            <style data-mode="LG"></style>
                            <style data-mode="MD" data-visited="true"></style>
                            <style data-mode="SM" data-visited="true"></style>
                            <style data-mode="XS" data-visited="true"></style>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-4" data-image-width="4000" data-image-height="5000" data-pswp-item-id="3" data-gallery-uid="1">
                            <div className="u-back-slide">
                                <img className="u-back-image u-expanded" src={report4} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-4">
                                <h3 className="u-gallery-heading"></h3>
                                <p className="u-gallery-text"></p>
                            </div>
                            <style data-mode="XL"></style>
                            <style data-mode="LG"></style>
                            <style data-mode="MD" data-visited="true"></style>
                            <style data-mode="SM" data-visited="true"></style>
                            <style data-mode="XS" data-visited="true"></style>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-5" data-image-width="4000" data-image-height="5000" data-pswp-item-id="4" data-gallery-uid="1">
                            <div className="u-back-slide">
                                <img className="u-back-image u-expanded" src={report5} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-5">
                                <h3 className="u-gallery-heading"></h3>
                                <p className="u-gallery-text"></p>
                            </div>
                            <style data-mode="XL"></style>
                            <style data-mode="LG"></style>
                            <style data-mode="MD" data-visited="true"></style>
                            <style data-mode="SM" data-visited="true"></style>
                            <style data-mode="XS" data-visited="true"></style>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-6" data-image-width="4000" data-image-height="5000" data-pswp-item-id="5" data-gallery-uid="1">
                            <div className="u-back-slide">
                                <img className="u-back-image u-expanded" src={report6} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-6">
                                <h3 className="u-gallery-heading"></h3>
                                <p className="u-gallery-text"></p>
                            </div>
                            <style data-mode="XL"></style>
                            <style data-mode="LG"></style>
                            <style data-mode="MD" data-visited="true"></style>
                            <style data-mode="SM" data-visited="true"></style>
                            <style data-mode="XS" data-visited="true"></style>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-7 u-active u-carousel-item-left" data-image-width="4000" data-image-height="5000" data-pswp-item-id="6" data-gallery-uid="1">
                            <div className="u-back-slide">
                                <img className="u-back-image u-expanded" src={report7}/>
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-7">
                                <h3 className="u-gallery-heading"></h3>
                                <p className="u-gallery-text"></p>
                            </div>
                            <style data-mode="XL"></style>
                            <style data-mode="LG"></style>
                            <style data-mode="MD" data-visited="true"></style>
                            <style data-mode="SM" data-visited="true"></style>
                            <style data-mode="XS" data-visited="true"></style>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-8" data-image-width="4000" data-image-height="5000" data-pswp-item-id="7" data-gallery-uid="1">
                            <div className="u-back-slide">
                                <img className="u-back-image u-expanded" src={report8} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-6">
                                <h3 className="u-gallery-heading"></h3>
                                <p className="u-gallery-text"></p>
                            </div>
                            <style data-mode="XL"></style>
                            <style data-mode="LG"></style>
                            <style data-mode="MD" data-visited="true"></style>
                            <style data-mode="SM" data-visited="true"></style>
                            <style data-mode="XS" data-visited="true"></style>
                        </div>
                        <div className="u-carousel-item u-effect-fade u-gallery-item u-carousel-item-8" data-image-width="4000" data-image-height="5000" data-pswp-item-id="8" data-gallery-uid="1">
                            <div className="u-back-slide">
                                <img className="u-back-image u-expanded" src={report9} />
                            </div>
                            <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-6">
                                <h3 className="u-gallery-heading"></h3>
                                <p className="u-gallery-text"></p>
                            </div>
                            <style data-mode="XL"></style>
                            <style data-mode="LG"></style>
                            <style data-mode="MD" data-visited="true"></style>
                            <style data-mode="SM" data-visited="true"></style>
                            <style data-mode="XS" data-visited="true"></style>
                        </div>
                    </div>
                    <a className="u-absolute-vcenter-lg u-absolute-vcenter-md u-absolute-vcenter-sm u-absolute-vcenter-xl u-carousel-control u-carousel-control-prev u-grey-70 u-icon-circle u-opacity u-opacity-70 u-spacing-10 u-text-white u-carousel-control-1"
                       href="#carousel-f035" role="button" data-u-slide="prev">
            <span aria-hidden="true">
              <svg viewBox="0 0 451.847 451.847"><path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path></svg>
            </span>
                        <span className="sr-only">
              <svg viewBox="0 0 451.847 451.847"><path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path></svg>
            </span>
                    </a>
                    <a className="u-absolute-vcenter-lg u-absolute-vcenter-md u-absolute-vcenter-sm u-absolute-vcenter-xl u-carousel-control u-carousel-control-next u-grey-70 u-icon-circle u-opacity u-opacity-70 u-spacing-10 u-text-white u-carousel-control-2"
                       href="#carousel-f035" role="button" data-u-slide="next">
            <span aria-hidden="true">
              <svg viewBox="0 0 451.846 451.847"><path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path></svg>
            </span>
                        <span className="sr-only">
              <svg viewBox="0 0 451.846 451.847"><path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path></svg>
            </span>
                    </a>
                </div>
            </div>
        </section>
    </div>
}
export default HackerReports;
