import {USER_ADDRESS} from "../constants";
import {abi} from "../constants/abi";
import {useState, useEffect} from 'react';
import {VenonymousContract} from "../constants/contract"
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import transferNft from "../components/functions/transferNft";
import {findMethodABI} from "../utilities/util";
import getDataFromNftraritytool from "../components/functions/getDataFromNftraritytool";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Assets2 = () => {
    const [loading, setLoading] = useState(true);
    const [metadata, setMetadata] = useState([]);
    const [transferToken, setTransferToken] = useState(null);
    const [recipient, setRecipient] = useState('');
    const [attributesModelData, setAttributesModelData] = useState([]);
    const [transferModelData, setTransferModelData] = useState(0);

    useEffect(async () => {
        async function initialize() {
            if (USER_ADDRESS) {
                await loadAssets();
            }
            setLoading(false);
        }
        initialize()
    }, [])

    const loadAssets = async () => {
        let currentInWallet = await (VenonymousContract.method(findMethodABI(abi, 'walletOfOwner')).call(USER_ADDRESS));
        currentInWallet = currentInWallet.decoded[0]; //.sort(function (a, b) {

        let metas = await getDataFromNftraritytool(currentInWallet, 'venonymous')
        console.log(metas);
        setMetadata([...metas])
        setLoading(false);
        setMetadata([...metas])
    }

    const transfer = async (index) => {
        await transferNft(abi, VenonymousContract, recipient, metadata[index].result.edition)

        await loadAssets();
    }

    const handleAttributesClick = (data) => {
        setAttributesModelData(data);
    }

    const handleTransferClick = (data) => {
        const modal = document.getElementById('sec-989c');
        modal.style.display = 'block';
        setTransferModelData(data);
    }

    const closeModal = () => {
        const modal = document.getElementById('sec-989c');
        modal.style.display = 'none';
    }

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    if (loading) {
        return <ClipLoader color={'#0000000'} loading={loading} css={override} size={40}/>
    }

    import('./../assets2.css')

    return <div>

        <section className="u-clearfix u-image u-section-1" id="sec-30ea" data-image-width="640" data-image-height="360">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-body-alt-color u-text-default u-title u-text-1">Your Legion</h1>
                <h5 className="u-custom-font u-text u-text-body-alt-color u-text-default u-text-2">Total Venons: {metadata.length}</h5>
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        {metadata.map((token, index) =>
                            <div key={index} className={"nft-info-assets"}>
                                <div className="u-border-2 u-border-custom-color-3 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1">
                                    <div className="u-container-layout u-similar-container u-container-layout-1">
                                        <h3 className="u-custom-font u-font-oswald u-text u-text-default u-text-3">#{token.result.edition}</h3>
                                        <div className="u-border-4 u-border-custom-color-1 u-line u-line-horizontal u-line-1"></div>
                                        <h6 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">Rank: {token.result.rank}</h6>
                                        <LazyLoadImage alt="" className="u-image u-image-default u-image-1" data-image-width="1600" data-image-height="1600" src={token.img} />
                                            <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                                <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-dialog-link u-hover-none u-none u-text-black u-btn-1"
                                                   href="#sec-e80c" onClick={() => handleAttributesClick(token)}>Attributes</a>
                                            </p>
                                      <a onClick={() => setTransferToken(index)} href="#carousel_49c0" className="u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-dialog-link u-hover-palette-4-dark-3 u-radius-50 u-btn-2">Transfer</a>
                                      <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                        <a className="u-btn u-button-link u-button-style u-btn-1 u-none" href={token.imgOriginal} target="_blank">View original</a>
                                      </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>

        <section
            className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-4" id="sec-e80c">
            <div
                className="u-border-6 u-border-custom-color-2 u-container-style u-custom-color-3 u-dialog u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-valign-top u-container-layout-1">
                    <h1 className="u-custom-font u-text u-text-default u-text-1">Attributes:</h1>
                    <div className="u-custom-font u-font-oswald u-text u-text-default u-text-2">
                        {attributesModelData.result && attributesModelData.result.attributes.map((attribute) =>
                            <p style={{margin: '0'}}>
                                {attribute.trait_type}: {attribute.value}
                            </p>
                        )}
                    </div>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" >
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9"></use>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                         xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px"
                         id="svg-efe9"><rect x="7" y="0" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="2" height="16"></rect>
                        <rect x="0" y="7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="16"
                              height="2"></rect></svg>
                </button>
            </div>
        </section>
            )}


        <section
            className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-5"
            id="carousel_49c0">
            <div
                className="u-border-6 u-border-custom-color-2 u-container-style u-custom-color-3 u-dialog u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-container-layout-1">
                    <h1 className="u-custom-font u-text u-text-default u-text-1">Transfer:</h1>
                    <p className="u-align-center-xs u-custom-font u-font-oswald u-text u-text-default u-text-2"> Disclaimer
                        : Only send your VNFT to a official VeChainThor network wallet like Sync 2 or VeChainThor Mobile
                        wallet</p>
                    <div className="u-form u-form-1">
                        <div className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form" style={{padding: '10px'}}>
                            <div className="u-form-group u-form-name">
                                <label htmlFor="name-fba2" className="u-label">Transfer to Wallet Address</label>
                                <input type="text" placeholder="Enter a Valid VeChain Address"
                                       onChange={e => setRecipient(e.target.value)}
                                       name="Transfer" className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white"/>
                            </div>
                            <div className="u-align-center u-form-group u-form-submit">
                                <button onClick={() => transfer(transferToken)} href="#"
                                   className="u-border-none u-btn u-btn-submit u-button-style u-custom-color-2 u-btn-1">Transfer</button>

                            </div>
                        </div>
                    </div>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9"></use>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                         xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px"
                         id="svg-efe9"><rect x="7" y="0" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="2" height="16"></rect>
                        <rect x="0" y="7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width="16"
                              height="2"></rect></svg>
                </button>
            </div>
        </section>
        <style> </style>

    </div>
};

export default Assets2;
