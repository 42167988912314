import {USER_ADDRESS, ADDRESS_KEY, PROJECT_NAME} from "../../constants";
import {connex} from "../../constants/contract"
import logo from "../../images/Untitled_Artwork.png"
import hamburger from "../../images/2.png"
import twitterLogo from "../../images/733579.png"
import discordLogo from "../../images/5968756.png"

const Header = () => {
    const handleLogIn = async() => {
        connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Please sign the certificate to connect to ' + PROJECT_NAME
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(ADDRESS_KEY, result.annex.signer);
                document.location.href = "/";
            })
    };

    const handleLogout = () => {
        localStorage.removeItem(ADDRESS_KEY)
        document.location.href = "/";
    }

    return <header className="u-black u-clearfix u-header u-header" id="sec-e780">
        <div className="u-clearfix u-sheet u-sheet-1">
            <a href="/" className="u-image u-logo u-image-1" data-image-width="1600"
               data-image-height="1600">
                <img src={logo} className="u-logo-image u-logo-image-1" />            </a>
            <nav className="u-menu u-menu-dropdown u-offcanvas u-menu-1 u-enable-responsive" data-responsive-from="XL">
                <div className="menu-collapse" style={{ fontWeight: '1', letterSpacing: '0px' }}>
                    <a className="u-button-style u-custom-color u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-top-bottom-menu-spacing u-file-icon u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base u-text-white u-file-icon-1"
                       href="#">
                        <img src={hamburger} alt="" />
                    </a>
                </div>
                <div className="u-nav-container">
                    <ul className="u-nav u-unstyled u-nav-1">
                        <li className="u-nav-item"><a
                            className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base active"
                            href="/"  style={{ padding: '10px 20px' }}>Home</a>
                        </li>
                        <li className="u-nav-item"><a
                            className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
                            href="hacker-reports" style={{ padding: '10px 20px' }}>Hacker Reports</a>
                        </li>
                        <li className="u-nav-item"><a
                            className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
                            href="/roadmap" style={{ padding: '10px 20px' }}>Roadmap</a>
                        </li>
                        <li className="u-nav-item"><a
                            className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
                            href="/minting" style={{ padding: '10px 20px' }}>Recruiting</a>
                        </li>
                        <li className="u-nav-item"><a
                            className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
                            href="/your-legion" style={{ padding: '10px 20px' }}>Your Legion</a>
                        </li>
                        <li className="u-nav-item"><a
                            className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base"
                            href="/your-ganja-girls" style={{ padding: '10px 20px' }}>Your Ganja girls</a>
                        </li>
                    </ul>
                </div>
                <div className="u-nav-container-collapse">
                    <div
                        className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                        <div className="u-inner-container-layout u-sidenav-overflow">
                            <div className="u-menu-close"></div>
                            <ul className="u-align-center u-nav u-palette-1-base u-popupmenu-items u-unstyled u-nav-2">
                                <li className="u-nav-item"><a className="u-button-style u-nav-link active"
                                                              href="/">Home</a>
                                </li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                              href="/hacker-reports">Hacker Reports</a>
                                </li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                              href="/roadmap">Roadmap</a>
                                </li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                              href="/minting">Recruiting</a>
                                </li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                              href="/your-legion">Your Legion</a>
                                </li>
                                <li className="u-nav-item"><a className="u-button-style u-nav-link"
                                                              href="/your-ganja-girls">Your Ganja Girls</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="u-black u-menu-overlay u-opacity u-opacity-70"></div>
                </div>
                {/*<style className="offcanvas-style"> .u-offcanvas*/}
                {/*    .u-sidenav {flex - basis: 250px !important;} .u-offcanvas:not(.u-menu-open-right)*/}
                {/*    .u-sidenav {margin - left: -250px;} .u-offcanvas.u-menu-open-right*/}
                {/*    .u-sidenav {margin - right: -250px;} @keyframes*/}
                {/*    menu-shift-left {from {left: 0;} to {left: 250px;}} @keyframes*/}
                {/*    menu-unshift-left {from {left: 250px;} to {left: 0;}} @keyframes*/}
                {/*    menu-shift-right {from {right: 0;} to {right: 250px;}} @keyframes*/}
                {/*    menu-unshift-right {from {right: 250px;} to {right: 0;}}            </style>*/}
            </nav>
            <div className="u-social-icons u-spacing-10 u-social-icons-1">
                <a className="u-social-url" title="twitter" target="_blank" href="https://twitter.com/VenonymousV"><span
                    className="u-file-icon u-icon u-social-icon u-social-twitter u-icon-1"><img src={twitterLogo}
                                                                                                alt="" /></span>
                </a>
                <a className="u-social-url" title="Discord" target="_blank" href="https://discord.gg/uaNCXcKq3d"><span
                    className="u-file-icon u-icon u-social-icon u-social-instagram u-icon-2"><img
                    src={discordLogo} alt="" /></span>
                </a>
            </div>
            {USER_ADDRESS === null ?
            <button onClick={handleLogIn}
                className="u-border-2 u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-none u-radius-5 u-text-hover-white u-btn-1">Connect
                Wallet</button> :
                <button onClick={handleLogout}
                        className="u-border-2 u-border-hover-white u-border-white u-btn u-btn-round u-button-style u-none u-radius-5 u-text-hover-white u-btn-1">
                    {USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)}
                </button> }
        </div>
    </header>

    // <header>
    //     <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    //         <div className="container">
    //             <a className="navbar-brand" href="/">{PROJECT_NAME}</a>
    //             <button
    //                 className="navbar-toggler"
    //                 type="button"
    //                 data-bs-toggle="collapse"
    //                 data-bs-target="#navbarSupportedContent"
    //                 aria-controls="navbarSupportedContent"
    //                 aria-expanded="false"
    //                 aria-label="Toggle navigation">
    //                 <span className="navbar-toggler-icon"></span>
    //             </button>
    //             <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //                 <ul className="navbar-nav me-auto mb-2 mb-lg-0">
    //                     <li className="nav-item">
    //                         <a className="nav-link" href="/">Home</a>
    //                     </li>
    //                     <li className="nav-item">
    //                         <a className="nav-link" href="/minting">Minting</a>
    //                     </li>
    //                     <li className="nav-item">
    //                         <a className="nav-link" href="/assets">Assets</a>
    //                     </li>
    //                 </ul>
    //                 <span className="d-flex">
    //                     {USER_ADDRESS
    //                         ? <a className="btn btn-danger" onClick={handleLogout}>Logout</a>
    //                         : <a className="btn btn-success" onClick={handleLogIn}>Connect Wallet</a>
    //                     }
    //                 </span>
    //             </div>
    //         </div>
    //     </nav>
    // </header>
}

export default Header
