// mainnet
const NETWORK = 'main'
const NODE = 'https://mainnet.veblocks.net/'
const VENONYMOUS_CONTRACT_ADDRESS = '0x2FD3d1E1a3F1E072c89d67301a86a5ba850Ccd4E'
const GANJAGIRLS_CONTRACT_ADDRESS = '0x998C9d999Bd6AF31089E4d3Bc63A7D196f9A27cA'
const ADDRESS_KEY = 'wallet';

//testnet
// const NETWORK = 'test'
// const NODE = 'https://testnet.veblocks.net/'
// const VENONYMOUS_CONTRACT_ADDRESS = '0xb0bf6e9ab9dde0cad40f5d461595cde94bf2298b'
// const GANJAGIRLS_CONTRACT_ADDRESS = '0x68137cCDB5C357eDF9743f134AF308314d6eB35C'
// const ADDRESS_KEY = 'wallet_test';

const USER_ADDRESS = window.localStorage.getItem(ADDRESS_KEY);
const PROJECT_NAME = 'Venonymous'

export {
    NODE,
    NETWORK,
    VENONYMOUS_CONTRACT_ADDRESS,
    GANJAGIRLS_CONTRACT_ADDRESS,
    USER_ADDRESS,
    ADDRESS_KEY,
    PROJECT_NAME
}
