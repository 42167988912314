import {connex} from "../../constants/contract";
import {toast} from "react-toastify";
import {USER_ADDRESS} from "../../constants";
import {getReceipt, findMethodABI, sleep} from "../../utilities/util";

const transferNft = async (ABI, contract, recipient, tokenId) => {
    const balanceOfABI = findMethodABI(ABI, 'balanceOf');
    try {
        //check if recipient address is valid
        await contract.method(balanceOfABI).call(recipient);
    } catch {
        toast.error("Address is incorrect!", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });
    }

    const transferFromABI = findMethodABI(ABI, 'transferFrom')
    const transferFromMethod = contract.method(transferFromABI).caller(USER_ADDRESS);

    transferFromMethod.call(USER_ADDRESS, recipient, tokenId)
        .then(resp => {
            if (!!resp.vmError) {
                toast.error(resp.revertReason, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                });

                return false;
            }
        }).catch(err => {
        toast.error("There was an error: " + err, {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });
    })
    const resp = await connex.vendor.sign(
        'tx',
        [transferFromMethod.asClause(
            USER_ADDRESS,
            recipient,
            tokenId)
        ])
        .comment('Transaction signing - Transfer NFT')
        .request();

    let receipt = null;
    while (receipt == null) {
        receipt = await getReceipt(resp.txid);
        await sleep(1000);
    }

    toast.success('Send succeeded!', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
    });

    return true;
}

export default transferNft;
