import vision from '../images/FAAFD964-FFD5-4468-9C06-6D2A9B20EF5A.png'

const Home = () => {


    import('./../Home.css')
    return <div>
        <section className="u-clearfix u-image u-section-1" id="sec-a122" data-image-width="640"
                 data-image-height="360">
            <div
                className="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-sheet-1">
                <div
                    className="u-align-center-xs u-border-6 u-border-grey-75 u-container-style u-expanded-width-xs u-grey-90 u-group u-radius-16 u-shape-round u-group-1">
                    <div className="u-container-layout u-container-layout-1">
                        <h1 className="u-custom-font u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xl u-title u-text-1">Welcome</h1>
                        <h3 className="u-align-center-sm u-align-center-xs u-custom-font u-text u-text-default u-text-2">We
                            Are Venonymous!</h3>
                        <h6 className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xl u-custom-font u-text u-text-3">We
                            like to call ourselves Pioneers of the new World.<br/>&nbsp;<br/>government &amp; Media Would
                            call us Hackers, Scammers, Cyber Criminals and many more names that assume we are the
                            Bad guys...<br/>
                            <br/>That will Stop now...<br/>
                            <br/>We found our way to the VeChainThor BlockChain and we believe that with the
                            help of the VeFam.<br/>
                            <br/>
                            <span style={{fontWeight: '400', fontSize: '20px'}}>We can prove who we really are!</span>
                            <br/>
                            <br/>Are you going to help us Recruit all 2000 Venons?
                        </h6>
                    </div>
                </div>
            </div>
        </section>
        <section className="u-clearfix u-image u-section-2" id="sec-90dc" data-image-width="640"
                 data-image-height="360">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-custom-font u-text u-text-custom-color-1 u-text-default u-text-1">Our Vision</h2>
                <img className="u-image u-image-round u-preserve-proportions u-radius-10 u-image-1"
                     src={vision} alt="" data-image-width="2048"
                     data-image-height="2048" />
                    <div
                        className="u-align-center u-border-6 u-border-grey-75 u-container-style u-grey-90 u-group u-radius-16 u-shape-round u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h5 className="u-custom-font u-text u-text-2">Our Vision is to let people know what we stand
                                for!<br/>
                                <br/>That means:<br/>
                                <br/>- Freedom of Speech<br/>- Passive income<br/>- taking care of our
                                community<br/>- Multiple Airdrops, Events<br/>And much more!<br/>
                                <br/>Want to know more?<br/>
                                <br/>Join our <a href="https://discord.gg/uaNCXcKq3d"
                                                 className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-custom-color-1 u-btn-1"
                                                 target="_blank">Discord</a>
                            </h5>
                        </div>
                    </div>
            </div>
        </section>
        <section className="u-black u-clearfix u-section-3" id="sec-9d61">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h3 className="u-custom-font u-text u-text-default u-text-1">Meet the Team</h3>
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        <div
                            className="u-align-center u-border-6 u-border-grey-75 u-container-style u-gradient u-list-item u-radius-16 u-repeater-item u-shape-round u-list-item-1">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-1">
                                <div alt="" className="u-image u-image-circle u-image-1" src="" data-image-width="1600"
                                     data-image-height="1600"></div>
                                <h5 className="u-text u-text-2">Dryraisans</h5>
                                <p className="u-text u-text-3"> Co-creator of <br/>
                                    <a dir="ltr" href="https://twitter.com/VenonymousV" role="link"
                                       className="css-16my406 css-18t94o4 css-4rbku5 css-901oao r-1cvl2hr r-1loqt21 r-bcqeeo r-poiln3 r-qvutc0 u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1">@VenonymousV</a>
                                </p>
                            </div>
                        </div>
                        <div
                            className="u-align-center u-border-6 u-border-grey-75 u-container-style u-gradient u-list-item u-radius-16 u-repeater-item u-shape-round u-list-item-2">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
                                <div alt="" className="u-image u-image-circle u-image-2" src="" data-image-width="400"
                                     data-image-height="400"></div>
                                <h5 className="u-text u-text-4">Silly Onion</h5>
                                <p className="u-text u-text-5"> Co-Creator and Artist for <br/>
                                    <a dir="ltr" href="https://twitter.com/VenonymousV" role="link"
                                       className="css-16my406 css-18t94o4 css-4rbku5 css-901oao r-1cvl2hr r-1loqt21 r-bcqeeo r-poiln3 r-qvutc0 u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2">@VenonymousV</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="u-clearfix u-image u-section-4" id="sec-9014" data-image-width="1280"
                 data-image-height="720">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-align-center u-custom-font u-text u-text-white u-text-1">Are you ready to join the
                    cause?</h2>
                <a href="https://discord.com/invite/uaNCXcKq3d"
                   className="u-border-none u-btn u-btn-round u-button-style u-custom-color-1 u-hover-custom-color-2 u-radius-6 u-btn-1">Discord</a>
                <a href="/minting"
                   className="u-border-none u-btn u-btn-round u-button-style u-custom-color-1 u-hover-custom-color-2 u-radius-6 u-btn-2">Minting</a>
                <h1 className="u-align-center u-custom-font u-text u-text-white u-text-2">WE ARE VENONYMOUS!</h1>
            </div>
        </section>

    </div>
};

export default Home;
