import {USER_ADDRESS} from "../constants";
import {abi} from "../constants/abi";
import {useState, useEffect} from 'react';
import {connex, VenonymousContract} from "../constants/contract"
import {findMethodABI} from "../utilities/util";
import BigNumber from "bignumber.js";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from 'react-toastify';
import {css} from "@emotion/react";
import {getReceipt} from '../utilities/getReceipt'

const Minting = () => {
    const [totalSupply, setTotalSupply] = useState(0);
    const [mintedAmount, setMintedAmount] = useState(0);
    const [mintPrice, setMintPrice] = useState(0);
    const [currentMintInfo, setCurrentMintInfo] = useState({});
    const [mintAmount, setMintAmount] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        await initialLoad();
    }, [])

    const initialLoad = async () => {
        const totalSupplyABI = findMethodABI(abi, 'totalSupply');
        const totalSupplyMethod = await VenonymousContract.method(totalSupplyABI).call();
        setTotalSupply(totalSupplyMethod.decoded[0]);

        const availableTokenCountABI = findMethodABI(abi, 'availableTokenCount');
        const availableTokenCountMethod = await VenonymousContract.method(availableTokenCountABI).call();
        setMintedAmount(parseInt(availableTokenCountMethod.decoded[0]));

        const getCurrentSaleTypeABI = findMethodABI(abi, 'getCurrentSaleType');
        const getCurrentSaleTypeMethod = await VenonymousContract.method(getCurrentSaleTypeABI).call();
        const currentSaleType = getCurrentSaleTypeMethod.decoded[0];

        let currentSalesInfo = {
            maxTokens: 0,
            period: 0,
            price: 0,
            saleType: 0
        };
        if (currentSaleType !== undefined) {

            const getSalesInfoABI = findMethodABI(abi, 'saleInfo');
            const getSalesInfo = await VenonymousContract.method(getSalesInfoABI).call(parseInt(currentSaleType));
            currentSalesInfo = getSalesInfo.decoded;
        }
        setCurrentMintInfo(currentSalesInfo)

        setMintPrice(currentSalesInfo['price'])
        setLoading(false);
    }

    const mint = async () => {
        if (!USER_ADDRESS) {
            toast.error('Connect your wallet!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });

            return;
        }

        const mintABI = findMethodABI(abi, 'mint');
        const mintMethod = VenonymousContract.method(mintABI).caller(USER_ADDRESS);
        const totalPrice = new BigNumber(mintPrice * mintAmount).toFixed();
        const clause = mintMethod.value(totalPrice).asClause(mintAmount)

        const resp = await connex.vendor.sign(
            'tx',
            [clause])
            .comment('Transaction signing - Mint NFT')
            .accepted(() => console.log('accepted'))
            .request()
        ;

        setLoading(true);

        let receipt = null;
        while (receipt == null) {
            receipt = await getReceipt(resp.txid);
            await sleep(1000);
        }

        if (receipt.reverted) {
            toast.error('Minting failed, try again! (set gas fees higher)', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });
        } else {
            toast.success('Minting succeeded!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });
        }

        await initialLoad();
    }

    const sleep = m => new Promise(r => setTimeout(r, m))

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    if (loading) {
        return <ClipLoader color={'#0000000'} loading={loading} css={override} size={40}/>
    }

    import('./../Recruiting.css')

    return <section className="u-clearfix u-image u-section-1" id="sec-65e6" data-image-width="640"
                    data-image-height="360">
        <div className="u-clearfix u-sheet u-sheet-1">
            <h1 className="u-align-center u-custom-font u-text u-text-body-alt-color u-text-1">Recruit your Legion</h1>
            <div
                className="u-align-center u-border-2 u-border-custom-color-1 u-container-style u-expanded-width-xs u-grey-80 u-group u-group-1">
                <div className="u-container-layout u-container-layout-1">
                    <div className="u-text u-text-default u-text-2">
                      <span style={{ fontSize: '30px' }}>Minted<b>:</b> {totalSupply-mintedAmount}/{totalSupply} Venons</span>
                    <br />
                        <br />Recruit your Legion now !<br />< br />

                        <table align={"center"}>
                            <tbody>
                            <tr>
                                <td align={"left"}>Price Pre-Sale (White Listed) </td>
                                <td>:</td>
                                <td>500 VET</td>
                            </tr>
                            <tr>
                                <td align={"left"}>Price Public sale</td>
                                <td>:</td>
                                <td>750 VET</td>
                            </tr>
                            <tr>
                                <td align={"left"}>current Mint price</td>
                                <td>:</td>
                                <td>{mintPrice > 0 ? (mintPrice / ("1e" + 18)) + ' VET' : 'disabled'}</td>
                            </tr>
                            </tbody>
                        </table>
                    <br />How many Venons do you want to recruit ?&nbsp;<br />
                    {/*<br />*/}
                    {/*    <input disabled={mintPrice <= 0 ? 1 : 0} type={"number"} className="u-shape u-shape-rectangle u-white u-shape-1" min={1} value={mintAmount} max={currentMintInfo['maxTokens']} onChange={num=>setMintAmount(Number(num.target.value))} name="Amount" ></input>*/}
                    {/*<br />*/}
                    {/*<br />Total : {mintPrice <= 0 ? 'Mint currently disabled' : (mintPrice / ("1e" + 18)) * mintAmount + ' VET'}<br />*/}
                    {/*    <button disabled={mintPrice <= 0 ? 1 : 0} onClick={mint} className="u-border-none u-btn u-btn-round u-button-style u-custom-color-2 u-hover-custom-color-1 u-radius-50 u-btn-2">Recruit</button><br />*/}
                    <br />The presale will start on the 1st of July 2022 at 10 AM UTC +0<br />The public sale will start on the 2nd of July 2022 at 10 AM UTC +0<br />
                    <br />After getting a Venonymous NFT&nbsp; you
                    will be able to find it in the "<a
                    href="Your-Legion.html"
                    data-page-id="911816024"
                    className="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1">Your
                    Legion</a>" Tab in the menu
                    </div>


                </div>
            </div>
        </div>
    </section>


        };

        export default Minting;
