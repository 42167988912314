import {connex} from "../constants/contract"

const getReceipt = async (txId) => {
    const transaction = connex.thor.transaction(txId);
    const receipt = await transaction.getReceipt();

    return receipt;
}

const sleep = m => new Promise(r => setTimeout(r, m))

const findMethodABI = (ABI, method) => ABI[ABI.findIndex(mthd => mthd.name === method)]

export {
    getReceipt,
    sleep,
    findMethodABI
};
