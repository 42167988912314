import roadmap from './../images/ROADMAP_4.0.png'
import {css} from "@emotion/react";
const Roadmap = () => {

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    import('./../Roadmap.css')
    return <div>
        <section className="u-clearfix u-image u-section-1" id="sec-cfc1" data-image-width="640"
                 data-image-height="360">
            <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                <img className="u-border-6 u-border-grey-75 u-image u-image-round u-radius-16 u-image-1"
                     src={roadmap} alt="" data-image-width="1600" data-image-height="900" />
            </div>
        </section>
    </div>
}
export default Roadmap;
